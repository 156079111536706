import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import axios from "axios";

const MAX_CLICKS = 5;
const HALF_DAY_IN_MS = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

export default function QuinnAI() {
  const [input, setInput] = useState<string>("");
  const [result, setResult] = useState<string>("");
  const [clicks, setClicks] = useState<number>(() =>
    parseInt(localStorage.getItem("clicks") || "0")
  );
  const [lastClick, setLastClick] = useState<number>(() =>
    parseInt(localStorage.getItem("lastClick") || "0")
  );
  const [placeholder, setPlaceholder] = useState<string>(
    "what do you want to ask quinn ai?"
  );

  useEffect(() => {
    const currentTime = Date.now();
    if (currentTime - lastClick >= HALF_DAY_IN_MS) {
      setClicks(0);
      localStorage.setItem("clicks", "0");
    }
  }, [lastClick]);

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const currentTime = Date.now();

    if (clicks >= MAX_CLICKS && currentTime - lastClick < HALF_DAY_IN_MS) {
      setResult("no more questions for today!");
      setPlaceholder("no more questions for today!");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/ask`,
        {
          input,
        }
      );

      setResult(response.data.result);
      setClicks((c) => {
        const newClicks = c + 1;
        localStorage.setItem("clicks", newClicks.toString());
        return newClicks;
      });
      setLastClick(currentTime);
      localStorage.setItem("lastClick", currentTime.toString());
    } catch (error: any) {
      console.error(error);
      alert(error.message || "An unexpected error occurred");
    }
  }

  function textInput(e: ChangeEvent<HTMLTextAreaElement>) {
    setInput(e.target.value);
  }

  return (
    <div>
      <h1>ask quinn ai</h1>
      <form onSubmit={onSubmit}>
        <textarea
          name="prompt"
          placeholder={placeholder}
          value={input}
          onChange={textInput}
          maxLength={100}
          rows={1}
          className="text-window"
        />
        <div className="flex-box" id="form">
          <input
            className="ask-button"
            type="submit"
            value={`ask (${MAX_CLICKS - clicks}/${MAX_CLICKS})`}
          />
        </div>
      </form>
      <br />
      <p>quinn ai's answer: {result}</p>
      <p className="disclaimer">
        <i>
          *note that responses are generated by an ai model, and may not be
          accurate.
        </i>
      </p>
    </div>
  );
}

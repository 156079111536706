import "../styles/logbook.css";
import "../styles/menu.css";

export default function Logbook() {
  return (
    <div className="logbook">
      <h1>logbook</h1>
      <b>Jan 2024</b>
      <br />- went to austin for a{" "}
      <a
        href="https://www.rabbitholeathon.com/"
        className="clickable"
        target="_blank"
        rel="noreferrer"
      >
        rabbitholeathon
      </a>
      <br />
      <b>Aug 2023</b>
      <br />- went viral on{" "}
      <a
        href="https://twitter.com/qvinnh/status/1688944756422836224?s=20"
        className="clickable"
        target="_blank"
        rel="noreferrer"
      >
        twitter
      </a>
      <br />
      <b>July 2023</b>
      <br />- broke staging lol
      <br />
      <b>June 2023</b>
      <br />- survived new york smog
      <br />
      <b>May 2023</b>
      <br />- started working at google on their editors accessibility team!
      <br />- moved to nyc for the summer
      <br />
      <b>Mar 2023</b>
      <br />- signed an offer with google for the summer! <br />
      <b>Jul 2022</b>
      <br />- biked my first century mile from toronto to niagara falls!
      <br />
      <b>June 2022</b>
      <br />- biked 75km for brain health with my friends :)
      <br />
      <b>May 2022</b>
      <br />- started my first pm internship with purolator!
      <br />
      <b>Feb 2022</b>
      <br />- snowboarded the west coast for the first time!
      <br />
      <b>May 2021</b>
      <br />- started my internship with the canadian space agency!
      <br />
      <b>Sept 2020</b>
      <br />- transferred into software engineering
      <br />
      <b>Nov 2019</b>
      <br />- went to my first concert (brockhampton)
      <br />
      <b>Sept 2019</b>
      <br />- started studying at mcmaster u
      <br />
      <br />
    </div>
  );
}

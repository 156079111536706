import React, { useEffect, useState } from "react";
import "../styles/floatingWindow.css";

interface FloatingWindowProps {
  onClose: () => void; // Callback function to close the window
  content: React.ReactNode; // Content to display inside the window
}

const FloatingWindow: React.FC<FloatingWindowProps> = ({
  onClose,
  content,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (!isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  return (
    <div className={`floating-window ${!isVisible ? "fade-out" : ""}`}>
      <div className="floating-window-content">{content}</div>
      <button className="close-button clickable" onClick={handleClose}>
        <p>x</p>
      </button>
    </div>
  );
};

export default FloatingWindow;

import React from "react";
import "../styles/menu.css";

interface MenuItemProps {
  name: string;
  cost: number;
  description: string;
  isLink?: boolean;
  linkUrl?: string;
  newTab?: boolean;
  onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  name,
  cost,
  description,
  isLink = false,
  linkUrl,
  newTab = true,
  onClick,
}) => {
  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.stopPropagation();
  };

  // Conditional rendering of the name, as a link or plain text
  const renderName = isLink ? (
    <a
      href={linkUrl}
      className="clickable"
      target={newTab ? "_blank" : "_self"}
      rel="noreferrer"
      onClick={handleLinkClick}
    >
      <b>{name}</b>
    </a>
  ) : onClick ? (
    <a>
      <b className="clickable">{name}</b>
    </a>
  ) : (
    <b>{name}</b>
  );

  return (
    <div className="menu__item " onClick={onClick ? onClick : undefined}>
      {renderName} ... {cost} <br />
      {description}
      <br />
    </div>
  );
};

export default MenuItem;

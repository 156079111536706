import { useState, useRef, ChangeEvent, FormEvent } from "react";
import emailjs from "@emailjs/browser";

export default function Reservations() {
  const [from_name, setFrom_name] = useState<string>("");
  const [input, setInput] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const form = useRef<HTMLFormElement>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const sendEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
          "service_l015waa",
          "template_6wtenxj",
          form.current,
          "h7fCoholBLJRR8yx7"
        )
        .then(
          () => {
            console.log("email sent");
            setIsSubmitted(true);
          },
          (error) => {
            console.log("email not sent", error.text);
          }
        );
    }
  };

  function from_nameInput(e: ChangeEvent<HTMLInputElement>) {
    setFrom_name(e.target.value);
  }
  function textInput(e: ChangeEvent<HTMLTextAreaElement>) {
    setInput(e.target.value);
  }
  function emailInput(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }
  return (
    <div>
      <h1>reservations</h1>
      <h4>for reservations, please leave a message</h4>
      <br />
      {isSubmitted ? (
        <div>thanks for sending a message, i'll get back to you soon :) </div>
      ) : (
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="from_name"
            placeholder="name"
            value={from_name}
            onChange={from_nameInput}
            className="text-window"
            required
          />

          <input
            type="email"
            name="reply_to"
            placeholder="email"
            value={email}
            onChange={emailInput}
            className="text-window"
            required
          />

          <textarea
            name="message"
            value={input}
            placeholder="message"
            onChange={textInput}
            className="text-window message"
            rows={1}
            required
          />
          <div className="flex-box" id="form">
            <input className="ask-button" type="submit" value="send" />
          </div>
        </form>
      )}
    </div>
  );
}

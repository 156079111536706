import React, { useState, useEffect } from "react";
import "../styles/menu.css";
import CardBack from "./back";
import CardFront from "./front";
import { CSSTransition } from "react-transition-group";

type Theme = "sun" | "moon";

function Menu() {
  const [isFlipped, setIsFlipped] = useState(false);
  const [theme, setTheme] = useState("");
  const [visible, setVisible] = useState(true);

  const handleCardClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    let targetElement = event.target as HTMLElement;
    while (targetElement !== null) {
      if (
        targetElement.tagName &&
        ["a", "textarea", "form", "button"].includes(
          targetElement.tagName.toLowerCase()
        )
      ) {
        return;
      }

      targetElement = targetElement.parentElement as HTMLElement;
    }
    setIsFlipped(!isFlipped);
  };

  const handleSelfClick = () => {
    setIsFlipped(!isFlipped);
  };

  const handleThemeChange = (newTheme: string) => {
    setTheme(newTheme);
    document.body.classList.remove("sun", "moon");
    document.body.classList.add(newTheme);
    setVisible(false);
    setTimeout(() => setVisible(true), 0);
  };

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme") as Theme | null;
    let themeToSet = "sun";
    if (currentTheme) {
      themeToSet = currentTheme;
    } else {
      const prefersDarkMode = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (prefersDarkMode) {
        themeToSet = "moon";
      }
    }
    setTheme(themeToSet);
    document.body.classList.add(themeToSet); // Apply the theme class to the body
    localStorage.setItem("theme", themeToSet);
  }, []);

  return (
    <CSSTransition
      in={visible}
      appear={true}
      timeout={1000}
      classNames="fadeIn"
    >
      <div className={theme}>
        <div
          className={` ${isFlipped ? "card flipped" : "card"}`}
          onClick={handleCardClick}
        >
          {" "}
          <CardFront onThemeChange={handleThemeChange} />
          <CardBack
            onThemeChange={handleThemeChange}
            onCardClick={handleSelfClick}
          />
        </div>
      </div>
    </CSSTransition>
  );
}

export default Menu;

import React, { useEffect, useRef, useState } from "react";

interface CardFrontProps {
  onThemeChange: (newTheme: string) => void;
}

const CardFront: React.FC<CardFrontProps> = ({ onThemeChange }) => {
  const cardFrontRef = useRef<HTMLDivElement>(null);
  const [cardHeight, setCardHeight] = useState<string | undefined>(undefined);

  useEffect(() => {
    const adjustHeight = () => {
      if (window.innerWidth < 700) {
        const cardBack = document.querySelector(".card_back");
        if (cardBack) {
          setCardHeight(`${cardBack.clientHeight}px`);
          console.log(cardBack.clientHeight);
        }
      } else {
        setCardHeight(undefined); // Reset to default if condition is not met
      }
    };

    // Adjust height on mount and window resize
    adjustHeight();
    window.addEventListener("resize", adjustHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", adjustHeight);
    };
  }, [onThemeChange]); // Whenever theme changes, reset the calc (works somehow)

  return (
    <div
      ref={cardFrontRef}
      className="card_front"
      style={{ minHeight: cardHeight }}
    >
      <h3 className="card_title">quinn ha</h3>
      <p className="card_subtitle">a menu by</p>
    </div>
  );
};

export default CardFront;
